const path = (root, sublink) => `${root}${sublink}`;

const AUTH_ROOT = '/auth';

export const PATHS = {
  root: '/',
  erp: 'https://erp.mak.mn',

  // ####################### AUTH PATHS #######################
  login: path(AUTH_ROOT, '/login/email'),
  loginPassword: path(AUTH_ROOT, '/login/password'),
  register: path(AUTH_ROOT, '/register/check'),
  createRegistration: path(AUTH_ROOT, '/register/create-registration'),
  verify: path(AUTH_ROOT, '/verify'),
  forgotPassword: path(AUTH_ROOT, '/forgot-password'),
  newPassword: path(AUTH_ROOT, '/new-password'),

  // ####################### PAGE PATHS #######################
  home: '/home',
  profile: '/user/profile',
  news: '/news',
  healthnews: '/healthnews',
  files: '/files',
  course: '/course/list',
  gallery: '/gallery',
  employees: '/employees',
  holiday: '/holiday/list',
  hr_tr: '/hr_tr/list',
  asset: '/asset',
  expense: '/expense/list',
  payment: '/payment/list',
  kpi: '/kpi',
  kpi_personal: '/kpi/kpi_personal',
  jobs: '/jobs',
  feedback: '/feedback',
  regulations: '/regulations',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const UNAUTHORIZED_PATHS = [
  PATHS.login,
  PATHS.loginPassword,
  PATHS.register,
  PATHS.createRegistration,
  PATHS.verify,
  PATHS.forgotPassword,
  PATHS.newPassword,
];

export const AUTHORIZED_PATHS = [
  PATHS.home,
  PATHS.profile,
  PATHS.news,
  PATHS.healthnews,
  PATHS.files,
  PATHS.course,
  PATHS.gallery,
  PATHS.employees,
  PATHS.holiday,
  PATHS.hr_tr,
  PATHS.asset,
  PATHS.expense,
  PATHS.payment,
  PATHS.jobs,
  PATHS.feedback,
  PATHS.regulations,
  PATHS.comingSoon,
  PATHS.maintenance,
  PATHS.page403,
  PATHS.page404,
  PATHS.page500,
];
